import { extent, median } from "d3-array";
import { addDays } from "date-fns";
import { ChartViewConfig } from "../../components/ChartOptions";
import { AreaChart, AreaChartData } from "../../components/charts/AreaChart";
import { Test } from "../../types/test";
import { filterAnomalies } from "./filterAnomalies";
import { filterHampel } from "./filterHampel";

function applyTransformations(
  chartConfig: ChartViewConfig,
  dataPoints: AreaChartData[],
  applyTransformations: ((
    chartViewConfig: ChartViewConfig,
    dataPoints: AreaChartData[]
  ) => AreaChartData[])[]
) {
  let currentDataPoints = [...dataPoints];
  for (let transform of applyTransformations) {
    currentDataPoints = transform(chartConfig, currentDataPoints);
  }
  return currentDataPoints;
}

export function formatDataForChart(
  tests: Test[],
  valueFn: (test: Test) => number | undefined,
  chartConfig: ChartViewConfig
): AreaChartData[] {
  const dataPointsWithAnomalies = tests
    .sort((a, b) => a.time - b.time)
    .map((test) => ({
      date: test.time,
      value: valueFn(test),
    }))
    .filter((data) => data.value !== undefined) as AreaChartData[];

  return applyTransformations(chartConfig, dataPointsWithAnomalies, [
    // Anomalies
    (chartConfig, dataPoints) =>
      filterHampel(chartConfig, filterAnomalies(chartConfig, dataPoints)),
    // Batching window
    (chartConfig, dataPoints) => {
      if (chartConfig.batchingWindow && chartConfig.batchingWindow > 0) {
        const [min, max] = extent(dataPoints, (d) => d.date);
        if (!min || !max) {
          return dataPoints;
        }
        let currentDate = min;
        let currentIndex = 0;
        const smoothedDataPoints = [];
        while (currentDate < max) {
          const valuesInWindow = [];
          while (
            dataPoints[currentIndex].date < currentDate &&
            currentIndex < dataPoints.length
          ) {
            currentIndex++;
            valuesInWindow.push(dataPoints[currentIndex].value);
          }
          if (valuesInWindow.length > 0) {
            smoothedDataPoints.push({
              date: currentDate,
              value: median(valuesInWindow)!,
            });
          }

          currentDate += chartConfig.batchingWindow * 1000 * 60;
        }

        return smoothedDataPoints;
      }
      return dataPoints;
    },
    // Moving average
    (chartConfig, dataPoints) => {
      const { movingAverage } = chartConfig;
      if (movingAverage && movingAverage > 1) {
        const averagedDataPoints: AreaChartData[] = [];
        const halfWindow = Math.floor(movingAverage / 2);
        for (let i = halfWindow; i < dataPoints.length - halfWindow; i++) {
          const movingAverageValue =
            dataPoints
              .slice(i - halfWindow, i + halfWindow + 1)
              .map((d) => d.value)
              .reduce((a, b) => a + b) / movingAverage;
          averagedDataPoints.push({
            date: dataPoints[i].date,
            value: movingAverageValue,
          });
        }

        return averagedDataPoints;
      }
      return dataPoints;
    },
    // Date range
    (chartConfig, dataPoints) => {
      const { dates } = chartConfig;
      if (dates) {
        const { startDate, endDate } = dates;
        return dataPoints.filter(
          (d) =>
            startDate &&
            d.date >= startDate.getTime() &&
            endDate &&
            d.date < addDays(endDate, 1).getTime()
        );
      }
      return dataPoints;
    },
  ]);
}
