import React from "react";
import { Header } from "../components/Header";
import { PageLayout } from "../layouts/PageLayout";
import { SetSiteForm } from "../components/forms/SetSiteForm";

export function CreateSite() {
  return (
    <>
      <Header title="Create site" />
      <PageLayout>
        <SetSiteForm />
      </PageLayout>
    </>
  );
}
