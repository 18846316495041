import React from "react";
import { Routes } from "./routes";
import {
  useFirebaseApp,
  preloadFirestore,
  preloadFunctions,
  preloadAuth,
  useUser,
} from "reactfire";
import firebase from "firebase";
import "firebase/auth";

// create a preload function to combine multiple preloads
const preloadSDKs = (firebaseApp: firebase.app.App) => {
  return Promise.all([
    preloadAuth({
      firebaseApp,
      setup(auth) {
        if (window.location.hostname === "localhost") {
          return auth().useEmulator("localhost");
        }
        auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
      },
    }),
    preloadFirestore({
      firebaseApp,
      setup(firestore) {
        if (window.location.hostname === "localhost") {
          return firestore().useEmulator("localhost", 8080);
        }
      },
    }),
    preloadFunctions({
      firebaseApp,
      setup(functions) {
        if (window.location.hostname === "localhost") {
          return functions().useEmulator("localhost", 5001);
        }
      },
    }),
  ]);
};

export function App() {
  const firebaseApp = useFirebaseApp();

  // Kick off fetches for SDKs and data that
  // we know our components will eventually need.
  //
  // This is OPTIONAL but encouraged as part of the render-as-you-fetch pattern
  // https://reactjs.org/docs/concurrent-mode-suspense.html#approach-3-render-as-you-fetch-using-suspense
  preloadSDKs(firebaseApp).then(() => Promise.resolve());

  return <Routes />;
}
