import React from "react";
import { Field, Form } from "react-final-form";
import { useAgents } from "../../data/useAgents";
import { useSetSite } from "../../data/useSetSite";
import { useSite } from "../../data/useSites";
import { FieldWrapper } from "../FieldWrapper";

export function SetSiteForm({ siteId }: { siteId?: string }) {
  const setSite = useSetSite();
  const { site } = useSite(siteId || "") || {};
  const agents = useAgents();

  return (
    <Form
      initialValues={{
        schedule: site?.schedule ?? "never",
        name: site?.name,
        agents: site?.agents ?? [],
        serverlessAgents: site?.serverlessAgents ?? [],
        batch: site?.batch ?? "1",
        apiKey: site?.apiKey ?? "",
        viewers: (site?.viewers ?? []).join(", "),
        webPageReplay: site?.webPageReplay ?? [],
      }}
      onSubmit={async (data) => {
        setSite(
          {
            name: data.name,
            schedule: data.schedule,
            agents: data.agents,
            batch: data.batch,
            serverlessAgents: data.serverlessAgents,
            apiKey: data.apiKey,
            viewers: data.viewers.split(",").map((v: string) => v.trim()),
            webPageReplay: data.webPageReplay,
          },
          siteId
        );
      }}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <FieldWrapper>
            <label htmlFor="name">Name</label>
            <Field id="name" name="name" component="input" placeholder="Name" />
          </FieldWrapper>
          <FieldWrapper>
            <label htmlFor="name">Test schedule</label>
            <Field id="schedule" name="schedule" component="select">
              <option value="never">Never</option>
              <option value="daily">Daily</option>
              <option value="hourly">Hourly</option>
              <option value="fifteen-minutely">Fifteen minutely</option>
            </Field>
          </FieldWrapper>
          <FieldWrapper>
            <label htmlFor="name">Batch runs</label>
            <Field id="batch" name="batch" component="select">
              <option value="1">1</option>
              <option value="5">5</option>
              <option value="10">10</option>
            </Field>
          </FieldWrapper>
          <FieldWrapper>
            <label htmlFor="agents">Agents</label>
            {agents.map((agent) => (
              <>
                <label>
                  <Field
                    key={agent.id}
                    name="agents"
                    component="input"
                    type="checkbox"
                    value={agent.id}
                  />{" "}
                  <span>{agent.name}</span>
                </label>
              </>
            ))}
          </FieldWrapper>
          <FieldWrapper>
            <label htmlFor="serverlessAgents">Serverless agents</label>
            <label>
              <Field
                name="serverlessAgents"
                component="input"
                type="checkbox"
                value={"functions"}
              />{" "}
              <span>Enabled</span>
            </label>
          </FieldWrapper>
          <FieldWrapper>
            <label htmlFor="webPageReplay">Web page replay</label>
            <label>
              <Field
                name="webPageReplay"
                component="input"
                type="checkbox"
                value={"enabled"}
              />{" "}
              <span>Enabled</span>
            </label>
          </FieldWrapper>
          <FieldWrapper>
            <label htmlFor="apiKey">API key</label>
            <label>
              <Field name="apiKey" component="input" type="text" />{" "}
            </label>
          </FieldWrapper>
          <FieldWrapper>
            <label htmlFor="viewers">Viewers</label>
            <label>
              <Field name="viewers" component="input" type="text" />{" "}
            </label>
          </FieldWrapper>
          <button type="submit">Save</button>
        </form>
      )}
    />
  );
}
