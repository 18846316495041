import { useFirestore, useFirestoreCollectionData } from "reactfire";
import { Test } from "../types/test";
import { usePage } from "./usePages";

export function useTests(pageId: string) {
  const { page } = usePage(pageId) || {};

  const testsRef = useFirestore()
    .collection("test")
    .where("owner", "==", page?.owner ?? "none")
    .where("page", "==", pageId);
  const tests = useFirestoreCollectionData<Test>(testsRef, { idField: "id" });
  return tests.data || [];
}
