import React, { ReactNode } from "react";
import { useLocation, Redirect } from "react-router-dom";
import { useSigninCheck } from "reactfire";

export function LoginRedirect({
  children,
}: {
  children: ReactNode;
}): JSX.Element | null {
  const { status, data: signInCheckResult } = useSigninCheck();

  const location = useLocation();
  if (status === "loading") {
    return null;
  }

  if (signInCheckResult.signedIn === true) {
    return <>{children}</>;
  } else {
    return <Redirect to={`/login?continue=${location.pathname}`} />;
  }
}
