import React, { useState } from "react";
import { Header } from "../components/Header";
import { PageLayout } from "../layouts/PageLayout";
import {
  useAuth,
  useFirestore,
  useFirestoreCollectionData,
  useFunctions,
} from "reactfire";
import { Test } from "../types/test";

export function Debug() {
  const auth = useAuth();
  const functions = useFunctions();
  const uid = auth.currentUser?.uid;
  const testsRef = useFirestore()
    .collection("test")
    .where("owner", "==", uid || "none");
  const tests = useFirestoreCollectionData<Test>(testsRef, { idField: "id" });
  const firestore = useFirestore();
  const [progress, setProgress] = useState(0);

  return (
    <>
      <Header title={"Debug"} />
      <PageLayout>
        <h2>Debug</h2>
        <h3>Migrate CSS</h3>
        <div>{tests.data && tests.data.length} tests.</div>
        <button
          onClick={async () => {
            await tests.data.reduce(async (prevPromise, test, i) => {
              await prevPromise;
              setProgress(i);
              console.log(`${(100 * i) / tests.data.length} %`);
              if (!test.result.css) {
                return Promise.resolve();
              }
              if (test.result.css.length < 100) {
                return Promise.resolve(); // this has likely already been migrated
              }
              const newCssDoc = firestore.collection("css").doc();
              await newCssDoc.set({
                contents: test.result.css,
                owner: uid,
                test: test.id,
              });

              firestore.collection("test").doc(test.id).update({
                "result.css": newCssDoc.id,
              });
            }, Promise.resolve());

            alert("Done");
          }}
        >
          Migrate CSS
        </button>
        <meter
          value={progress}
          min={0}
          max={tests.data && tests.data.length}
        ></meter>
        <h3>Migrate ownership</h3>
        <button
          onClick={async () => {
            const migrateOwner = functions.httpsCallable("debugMigrateOwner");
            await migrateOwner();
            alert("Migration started");
          }}
        >
          Migrate CSS
        </button>
      </PageLayout>
    </>
  );
}
