import React from "react";
import { Switch, Route, BrowserRouter } from "react-router-dom";

import { LoginRedirect } from "./LoginRedirect";
import { Home } from "./Home";
import { Login } from "./Login";
import { CreateSite } from "./CreateSite";
import { CreatePage } from "./CreatePage";
import { ViewPage } from "./ViewPage";
import { ViewSite } from "./ViewSite";
import { Debug } from "./Debug";
import { CreateAgent } from "./CreateAgent";
import { ViewAgent } from "./ViewAgent";

export function Routes(): JSX.Element | null {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact>
          <LoginRedirect>
            <Home />
          </LoginRedirect>
        </Route>
        <Route path="/create/site" exact>
          <LoginRedirect>
            <CreateSite />
          </LoginRedirect>
        </Route>
        <Route path="/create/page">
          <LoginRedirect>
            <CreatePage />
          </LoginRedirect>
        </Route>
        <Route path="/create/agent">
          <LoginRedirect>
            <CreateAgent />
          </LoginRedirect>
        </Route>
        <Route path="/page/:id">
          <LoginRedirect>
            <ViewPage />
          </LoginRedirect>
        </Route>
        <Route path="/site/:id">
          <LoginRedirect>
            <ViewSite />
          </LoginRedirect>
        </Route>
        <Route path="/agent/:id">
          <LoginRedirect>
            <ViewAgent />
          </LoginRedirect>
        </Route>
        <Route path="/login" exact>
          <Login />
        </Route>
        <Route path="/debug" exact>
          <LoginRedirect>
            <Debug />
          </LoginRedirect>
        </Route>
      </Switch>
    </BrowserRouter>
  );
}
