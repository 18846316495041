import { useState } from "react";
import { DateRangePicker } from "react-date-range";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { Button, Popup } from "semantic-ui-react";

export function DateRange({
  value,
  onChange,
  name,
}: {
  value?: {
    startDate: Date;
    endDate: Date;
  };
  onChange: ({
    startDate,
    endDate,
  }: {
    startDate: Date;
    endDate: Date;
  }) => void;
  name: string;
}) {
  const [range, setRange] = useState(value);
  const [open, setOpen] = useState(false);

  return (
    <Popup
      open={open}
      trigger={
        <button
          name={name}
          onClick={() => {
            setOpen(!open);
          }}
        >
          {value
            ? `${value.startDate.toDateString()} – ${value.endDate.toDateString()}`
            : "Select dates"}
        </button>
      }
      header="Select dates"
      content={
        <>
          <DateRangePicker
            onChange={(item) => {
              setRange((item as any).selection);
            }}
            showSelectionPreview={false}
            moveRangeOnFirstSelection={false}
            months={2}
            ranges={[
              {
                startDate: range && range.startDate,
                endDate: range && range.endDate,
                key: "selection",
              },
            ]}
            direction="horizontal"
          />
          <Button
            onClick={() => {
              range && onChange(range);
              setOpen(false);
            }}
          >
            Update
          </Button>
        </>
      }
      on={["click"]}
    />
  );
}
