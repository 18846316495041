import React, { useEffect, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useAuth } from "reactfire";
import { Header } from "../components/Header";
import { PageLayout } from "../layouts/PageLayout";
import firebase from "firebase";
import * as firebaseui from "firebaseui";
import "firebaseui/dist/firebaseui.css";

type LoginParams = {
  continue: string;
};

let ui: firebaseui.auth.AuthUI;

export function Login(): JSX.Element | null {
  const history = useHistory();
  const location = useLocation<LoginParams>();
  const cont = new URLSearchParams(location.search).get("continue") || "/";
  const auth = useAuth();

  if (window.location.hostname === "localhost") {
    auth.useEmulator("http://localhost:9099");
  }

  useEffect(() => {
    ui = ui || new firebaseui.auth.AuthUI(firebase.auth());

    ui.start("#firebaseui-auth-container", {
      signInOptions: [
        {
          provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
          signInMethod:
            firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD,
        },
        {
          provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
          signInMethod: firebase.auth.GoogleAuthProvider.GOOGLE_SIGN_IN_METHOD,
        },
      ],
      callbacks: {
        signInSuccessWithAuthResult: () => {
          history.replace(cont);
          return true;
        },
        signInFailure: (e) => {
          alert(
            "There was some problem logging in. Check the console for details. Try again later?"
          );
          console.error(e);
        },
      },
    });
  }, [cont, history]);

  return (
    <>
      <Header title="Log in" />
      <PageLayout disableSideNav centered>
        <h1>Log in</h1>
        <div id="firebaseui-auth-container" />
      </PageLayout>
    </>
  );
}
