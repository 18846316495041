import React from "react";
import { Form, Field } from "react-final-form";
import { PageFields } from "../types/page";
import { Header } from "../components/Header";
import { PageLayout } from "../layouts/PageLayout";
import { useSetPage } from "../data/useSetPage";
import { useSites } from "../data/useSites";
import { FieldWrapper } from "../components/FieldWrapper";
import { useLocation, useHistory } from "react-router-dom";

export function CreatePage() {
  const history = useHistory();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);

  const allSites = useSites();
  const sites = allSites.filter((s) => s.role === "owner");
  const setPage = useSetPage();
  if (!sites || sites.length < 1) {
    return null;
  }
  return (
    <>
      <Header title="Create page" />
      <PageLayout>
        <Form
          onSubmit={async (data: PageFields) => {
            const newPage = await setPage({
              url: data.url,
              site: data.site,
            });
            history.replace(`/page/${newPage.id}`);
          }}
          render={({ handleSubmit, values }) => (
            <form onSubmit={handleSubmit}>
              <FieldWrapper>
                <label htmlFor="site">Site</label>
                <Field
                  name="site"
                  component="select"
                  initialValue={
                    searchParams.get("siteId") || sites[0].site.id || ""
                  }
                >
                  {sites.map(({ site }) => (
                    <option key={site.id} value={site.id}>
                      {site.name}
                    </option>
                  ))}
                </Field>
              </FieldWrapper>
              <FieldWrapper>
                <label htmlFor="url">URL</label>
                <Field
                  id="url"
                  name="url"
                  component="input"
                  placeholder="URL"
                  initialValue={""}
                />
              </FieldWrapper>
              <button type="submit">Submit</button>
            </form>
          )}
        />
      </PageLayout>
    </>
  );
}
