import React from "react";

var Diff = require("text-diff");

export function CssDiff({ css1, css2 }: { css1: string; css2: string }) {
  if (!css1! || !css2) {
    return <pre>CSS missing</pre>;
  }

  var diff = new Diff(); // options may be passed to constructor; see below
  var textDiff = diff.main(css1, css2).filter((a: any) => a[0] !== 0); // produces diff array
  const html = diff.prettyHtml(textDiff);
  return <div dangerouslySetInnerHTML={{ __html: html }}></div>;
}
