import React from "react";
import { Field, Form } from "react-final-form";
import { useSetAgent } from "../../data/useSetAgent";
import { useAgent } from "../../data/useAgents";
import { AgentFields } from "../../types/agent";
import { FieldWrapper } from "../FieldWrapper";

export function SetAgentForm({ agentId }: { agentId?: string }) {
  const setAgent = useSetAgent();
  const agent = useAgent(agentId || "");
  return (
    <Form
      key={agentId}
      onSubmit={async (data: AgentFields) => {
        setAgent(
          {
            name: data.name,
          },
          agentId
        );
        alert("Saved agent");
      }}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <FieldWrapper>
            <label htmlFor="name">Name</label>
            <Field
              id="name"
              name="name"
              component="input"
              placeholder="Name"
              initialValue={agent?.name}
            />
          </FieldWrapper>
          <button type="submit">Save</button>
        </form>
      )}
    />
  );
}
