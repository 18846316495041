import { styled } from "linaria/react";
import * as React from "react";
import { Link } from "react-router-dom";
import { Page } from "../types/page";

const Wrapper = styled.div`
  box-shadow: 0px 2px 8px rgb(0 0 0 / 15%);
  flex-shrink: 0;
  width: 400px;
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.02) !important;
`;

const WrapperInner = styled.div`
  display: flex;
  flex-direction: column;
`;

const Image = styled.img`
  width: 100%;
  height: 300px;
`;

const Footer = styled.div`
  margin: var(--grid) 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const placeholderImage = `data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==`;

export function PageCard({ page }: { page: Page }) {
  return (
    <Wrapper>
      <Link to={`/page/${page.id}`}>
        <WrapperInner>
          <Image
            alt={`screenshot for page ${page.url}`}
            src={page.screenshotUrl || placeholderImage}
          />
          <Footer>{page.url}</Footer>
        </WrapperInner>
      </Link>
    </Wrapper>
  );
}

export function CreatePageCard({ siteId }: { siteId: string }) {
  return (
    <Wrapper>
      <Link to={`/create/page?siteId=${siteId}`}>
        <WrapperInner>
          <Image
            alt={`create a page`}
            src={`${process.env.PUBLIC_URL}/plus.png`}
          />
          <Footer>Create page</Footer>
        </WrapperInner>
      </Link>
    </Wrapper>
  );
}
