import React, { useMemo, useCallback } from "react";
import { AreaClosed, Line, Bar, Circle, LinePath } from "@visx/shape";
import { curveStepAfter, curveMonotoneX as curveForChart } from "@visx/curve";
import { GridRows, GridColumns } from "@visx/grid";
import { scaleTime, scaleLinear } from "@visx/scale";
import { AxisLeft, AxisBottom } from "@visx/axis";

import {
  withTooltip,
  Tooltip,
  TooltipWithBounds,
  defaultStyles,
} from "@visx/tooltip";
import { WithTooltipProvidedProps } from "@visx/tooltip/lib/enhancers/withTooltip";
import { localPoint } from "@visx/event";
import { LinearGradient } from "@visx/gradient";
import { max, extent, bisector } from "d3-array";
import { timeFormat } from "d3-time-format";

import { Event } from "../../data/processing/extractEvents";

export type AreaChartData = { date: number; value: number };

type TooltipData = AreaChartData;

export const background = "#3b6978";
export const background2 = "#204051";
export const accentColor = "#edffea";
export const accentColorDark = "#75daad";
const tooltipStyles = {
  ...defaultStyles,
  background,
  border: "1px solid white",
  color: "white",
};

// util
const formatDate = timeFormat("%I:%M %p %b %d, '%y");
const formateDateShort = timeFormat("%I %p %b %d");

// accessors
const getDate = (d: AreaChartData) => new Date(d.date);
const getDataValue = (d: AreaChartData) => d.value;
const bisectDate = bisector<AreaChartData, Date>((d) => new Date(d.date)).left;

export type AreaProps = {
  data: AreaChartData[];
  pointOverlay?: AreaChartData[];
  eventOverlay?: Event[];
  width: number;
  height: number;
  curve?: "smooth" | "step";
  display?: "area" | "line";
  margin?: { top: number; right: number; bottom: number; left: number };
};

export const AreaChart = withTooltip<AreaProps, TooltipData>(
  ({
    data,
    pointOverlay,
    eventOverlay,
    width,
    height,
    margin = { top: 20, right: 20, bottom: 20, left: 50 },
    showTooltip,
    hideTooltip,
    tooltipData,
    tooltipTop = 0,
    tooltipLeft = 0,
    curve = "step",
    display = "area",
  }: AreaProps & WithTooltipProvidedProps<TooltipData>) => {
    if (width < 10 || data.length === 0) return null;

    // curve
    const curveFunction = curve === "smooth" ? curveForChart : curveStepAfter;

    // bounds
    const innerWidth = width - margin.left - margin.right;
    const innerHeight = height - margin.top - margin.bottom;

    // Axis scales
    const scaleHeight = innerHeight / 20;

    // scales
    const dateScale = useMemo(
      () =>
        scaleTime({
          range: [margin.left, innerWidth + margin.left],
          domain: extent(data, getDate) as [Date, Date],
        }),
      [innerWidth, margin.left, data]
    );
    const dataValueScale = useMemo(
      () =>
        scaleLinear({
          range: [innerHeight + margin.top - scaleHeight, margin.top],
          domain: [
            0,
            (max(data, getDataValue) || 0) < 1
              ? 1
              : (max(data, getDataValue) || 0) + innerHeight / 2,
          ],
          nice: true,
        }),
      [margin.top, innerHeight, data]
    );

    // tooltip handler
    const handleTooltip = useCallback(
      (
        event:
          | React.TouchEvent<SVGRectElement>
          | React.MouseEvent<SVGRectElement>
      ) => {
        const { x } = localPoint(event) || { x: 0 };
        const x0 = dateScale.invert(x);
        const index = bisectDate(data, x0, 1);
        const d0 = data[index - 1];
        const d1 = data[index];
        let d = d0;
        if (d1 && getDate(d1)) {
          d =
            x0.valueOf() - getDate(d0).valueOf() >
            getDate(d1).valueOf() - x0.valueOf()
              ? d1
              : d0;
        }
        showTooltip({
          tooltipData: d,
          tooltipLeft: x,
          tooltipTop: dataValueScale(getDataValue(d)),
        });
      },
      [showTooltip, dataValueScale, dateScale]
    );

    return (
      <div>
        <svg width={width} height={height}>
          <rect
            x={0}
            y={0}
            width={width}
            height={height}
            fill="url(#area-background-gradient)"
            rx={14}
          />
          <LinearGradient
            id="area-background-gradient"
            from={background}
            to={background2}
          />
          <LinearGradient
            id="area-gradient"
            from={accentColor}
            to={accentColor}
            toOpacity={0.1}
          />
          <GridRows
            left={margin.left}
            scale={dataValueScale}
            width={innerWidth}
            strokeDasharray="1,3"
            stroke={accentColor}
            strokeOpacity={0}
            pointerEvents="none"
          />
          <GridColumns
            top={margin.top}
            scale={dateScale}
            height={innerHeight}
            strokeDasharray="1,3"
            stroke={accentColor}
            strokeOpacity={0.2}
            pointerEvents="none"
          />
          {display === "area" && (
            <AreaClosed<AreaChartData>
              data={data}
              x={(d) => dateScale(getDate(d)) ?? 0}
              y={(d) => dataValueScale(getDataValue(d)) ?? 0}
              yScale={dataValueScale}
              strokeWidth={1}
              stroke="url(#area-gradient)"
              fill="url(#area-gradient)"
              curve={curveFunction}
            />
          )}
          <LinePath<AreaChartData>
            data={data}
            x={(d) => dateScale(getDate(d)) ?? 0}
            y={(d) => dataValueScale(getDataValue(d)) ?? 0}
            strokeWidth={2}
            stroke={display === "line" ? accentColor : "transparent"}
            curve={curveFunction}
          />
          {data.map((d, i) => (
            <Circle
              key={`point-${d.date}-${i}`}
              className="dot"
              cx={dateScale(getDate(d)) ?? 0}
              cy={dataValueScale(getDataValue(d)) ?? 0}
              r={tooltipData === d ? 3 : 0}
              fill={accentColorDark}
            />
          ))}
          {pointOverlay &&
            pointOverlay.map((d, i) => (
              <Circle
                key={`pointOverlay-${d.date}-${i}`}
                className="dot"
                cx={dateScale(getDate(d)) ?? 0}
                cy={dataValueScale(getDataValue(d)) ?? 0}
                r={3}
                stroke={accentColorDark}
                fillOpacity={0}
              />
            ))}
          <AxisLeft
            scale={dataValueScale}
            left={margin.left}
            numTicks={4}
            stroke={accentColor}
            tickStroke={accentColor}
            tickLabelProps={() => ({
              fill: "#fff",
              fontSize: 12,
              fontFamily: "sans-serif",
              textAnchor: "end",
              verticalAnchor: "middle",
              x: -10,
            })}
          />
          <AxisBottom
            top={innerHeight - scaleHeight + margin.top}
            scale={scaleTime({
              range: [margin.left, innerWidth + margin.left],
              domain: extent(data, getDate) as [Date, Date],
            })}
            tickFormat={(v) => formateDateShort(v)}
            stroke={accentColor}
            tickStroke={accentColor}
            tickLabelProps={() => ({
              fill: "#fff",
              fontSize: 12,
              fontFamily: "sans-serif",
              textAnchor: "middle",
            })}
            numTicks={4}
          />
          <Bar
            x={margin.left}
            y={margin.top}
            width={innerWidth}
            height={innerHeight}
            fill="transparent"
            rx={14}
            onTouchStart={handleTooltip}
            onTouchMove={handleTooltip}
            onMouseMove={handleTooltip}
            onMouseLeave={() => hideTooltip()}
          />
          {eventOverlay && (
            <g>
              {eventOverlay.map((event) => (
                <Line
                  from={{ x: dateScale(event.time), y: margin.top }}
                  to={{ x: dateScale(event.time), y: innerHeight }}
                  stroke={accentColor}
                  opacity={0.8}
                  strokeWidth={1}
                  pointerEvents="none"
                  strokeDasharray="5,3"
                />
              ))}
            </g>
          )}
          {tooltipData && (
            <g>
              <Line
                from={{ x: tooltipLeft, y: margin.top }}
                to={{ x: tooltipLeft, y: innerHeight }}
                stroke={accentColorDark}
                strokeWidth={2}
                pointerEvents="none"
                strokeDasharray="5,2"
              />
              <circle
                cx={tooltipLeft}
                cy={tooltipTop + 1}
                r={4}
                fill="black"
                fillOpacity={0.1}
                stroke="black"
                strokeOpacity={0.1}
                strokeWidth={2}
                pointerEvents="none"
              />
              <circle
                cx={tooltipLeft}
                cy={tooltipTop}
                r={4}
                fill={accentColorDark}
                stroke="white"
                strokeWidth={2}
                pointerEvents="none"
              />
            </g>
          )}
        </svg>
        {tooltipData && (
          <div>
            <TooltipWithBounds
              key={Math.random()}
              top={tooltipTop - 12}
              left={tooltipLeft + 12}
              style={tooltipStyles}
            >
              {`${getDataValue(tooltipData)}`}
            </TooltipWithBounds>
            <Tooltip
              top={innerHeight + margin.top - 14}
              left={tooltipLeft}
              style={{
                ...defaultStyles,
                minWidth: 72,
                textAlign: "center",
                transform: "translateX(-50%)",
              }}
            >
              {formatDate(getDate(tooltipData))}
            </Tooltip>
          </div>
        )}
      </div>
    );
  }
);
