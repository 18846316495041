import { addDays } from "date-fns";
import { styled } from "linaria/react";
import React, { useRef } from "react";
import { Field, Form } from "react-final-form";
import { DateRange } from "./DateRange";
import { FieldWrapper } from "./FieldWrapper";

export type ChartViewConfig = {
  dates?: {
    startDate?: Date;
    endDate?: Date;
  };
  batchingWindow?: number;
  curve?: "smooth" | "step";
  overlay?: "none" | "points" | "events";
  display?: "area" | "line";
  filterAnomalies?: "0.1" | "0.25" | "0.5" | "hampel";
  movingAverage?: number;
};

const ChartOptionsForm = styled.form`
  display: flex;
  flex-wrap: wrap;

  & > * {
    min-width: calc(var(--grid) * 20);
  }
`;

export function ChartOptions({
  onConfigChange,
}: {
  onConfigChange(e: ChartViewConfig): void;
}) {
  const ref = useRef<HTMLButtonElement>(null);

  return (
    <Form
      onSubmit={(data) => {
        onConfigChange({
          dates: data.dates,
          batchingWindow: Number(data.batchingWindow),
          curve: data.curve,
          display: data.display,
          overlay: data.overlay,
          filterAnomalies: data.filterAnomalies,
          movingAverage: Number(data.movingAverage),
        });
      }}
      render={({ handleSubmit }) => (
        <ChartOptionsForm
          onSubmit={handleSubmit}
          onChange={() => ref.current?.click()}
        >
          <FieldWrapper>
            <label htmlFor="Dates">Dates</label>
            <Field
              id="dates"
              name="dates"
              render={(props) => {
                return (
                  <DateRange
                    {...props.input}
                    // We want to spread, according to the react-final-form custom component API, but also click the ref.
                    // Since our custom component doesn't trigger an on change, we just do this manually here.
                    onChange={(...args) => {
                      props.input.onChange(...args);
                      ref.current?.click();
                    }}
                  />
                );
              }}
            ></Field>
          </FieldWrapper>
          <FieldWrapper>
            <label htmlFor="batchingWindow">Batching window</label>
            <Field id="batchingWindow" name="batchingWindow" component="select">
              <option value="0">No smoothing</option>
              <option value="5">5 minutes</option>
              <option value="60">1 hour</option>
              <option value="360">6 hours</option>
              <option value="1440">1 day</option>
            </Field>
          </FieldWrapper>
          <FieldWrapper>
            <label htmlFor="curve">Curve</label>
            <Field id="curve" name="curve" component="select">
              <option value="step">Step</option>
              <option value="smooth">Smooth</option>
            </Field>
          </FieldWrapper>
          <FieldWrapper>
            <label htmlFor="overlay">Overlay</label>
            <Field id="overlay" name="overlay" component="select">
              <option value="none">None</option>
              <option value="points">Points</option>
              <option value="events">Events</option>
            </Field>
          </FieldWrapper>
          <FieldWrapper>
            <label htmlFor="movingAverage">Moving average</label>
            <Field id="movingAverage" name="movingAverage" component="select">
              <option value="1">None</option>
              <option value="3">3</option>
              <option value="5">5</option>
              <option value="7">10</option>
            </Field>
          </FieldWrapper>
          <FieldWrapper>
            <label htmlFor="display">Display</label>
            <Field id="display" name="display" component="select">
              <option value="area">Area</option>
              <option value="line">Line</option>
            </Field>
          </FieldWrapper>
          <FieldWrapper>
            <label htmlFor="filterAnomalies">Filter anomalies</label>
            <Field
              id="filterAnomalies"
              name="filterAnomalies"
              component="select"
            >
              <option value="none">None</option>
              <option value="0.1">Filter (10% of either side)</option>
              <option value="0.25">Filter (25% of either side)</option>
              <option value="0.5">Filter (50% of either side)</option>
              <option value="hampel">Hampel</option>
            </Field>
          </FieldWrapper>
          <button ref={ref} hidden type="submit">
            Update chart config
          </button>
        </ChartOptionsForm>
      )}
    />
  );
}
