import { useFirestore, useAuth, useFirestoreCollectionData } from "reactfire";
import { Role } from "../types/role";
import { Site } from "../types/site";

export function useSites() {
  const sitesWithRoles: { [id: string]: { role: Role; site: Site } } = {};
  const auth = useAuth();
  const uid = auth.currentUser?.uid;
  const firestore = useFirestore();

  const ownerSitesRef = firestore
    .collection("site")
    .where("owner", "==", uid || "none");
  const ownerSites = useFirestoreCollectionData<Site>(ownerSitesRef, {
    idField: "id",
  });

  const viewerSitesRef = firestore
    .collection("site")
    .where("viewers", "array-contains", auth.currentUser?.email || "none");
  const viewerSites = useFirestoreCollectionData<Site>(viewerSitesRef, {
    idField: "id",
  });

  if (!uid) {
    return [];
  }

  (viewerSites.data || []).forEach((site) => {
    sitesWithRoles[site.id] = {
      role: "viewer",
      site,
    };
  });

  // Owner sites intentionally override viewer sites in case for some reason you're an owner and a viewer of a site
  (ownerSites.data || []).forEach((site) => {
    sitesWithRoles[site.id] = {
      role: "owner",
      site,
    };
  });

  return Object.values(sitesWithRoles);
}

export function useSite(id: string) {
  const sites = useSites();
  return sites.find(({ site }) => site.id === id);
}
