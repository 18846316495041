import { useFirestore, useAuth, useFirestoreCollectionData } from "reactfire";
import { Agent } from "../types/agent";

export function useAgents(ids?: string[]) {
  const auth = useAuth();
  const uid = auth.currentUser?.uid;
  const agentsRef = useFirestore()
    .collection("agent")
    .where("owner", "==", uid || "none");
  const sites = useFirestoreCollectionData<Agent>(agentsRef, { idField: "id" });
  const data = sites.data;
  if (!uid || !data) {
    return [];
  }
  return data;
}

export function useAgent(id: string) {
  const agents = useAgents();
  return agents.find((agent) => agent.id === id);
}
