import React from "react";
import { Header } from "../components/Header";
import { useParams } from "react-router-dom";
import { PageLayout } from "../layouts/PageLayout";
import { useAgent } from "../data/useAgents";
import { SetAgentForm } from "../components/forms/SetAgentForm";

export function ViewAgent() {
  const { id } = useParams<{ id: string }>();
  const agent = useAgent(id);

  return (
    <>
      <Header title={agent?.name} />
      <PageLayout>
        <h2>Agent configuration</h2>
        <SetAgentForm agentId={id} />
        <h2>Setup</h2>
        <pre>
          docker run -d -e FS_AGENT_ID={id} jpnelson/faster-style:latest
        </pre>
      </PageLayout>
    </>
  );
}
