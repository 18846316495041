import * as React from "react";
import { styled } from "linaria/react";
import { Link, useLocation, useParams } from "react-router-dom";
import { usePages } from "../../data/usePages";
import { useSites } from "../../data/useSites";
import { Icon } from "semantic-ui-react";
import { useAgents } from "../../data/useAgents";

const NavWrapper = styled.nav`
  width: 300px;
  height: 100%;
  position: fixed;
  background-color: var(--side-nav-background);
  box-shadow: 0 3px 4px 1px #ccc;
  overflow: auto;
`;

const NavGroup = styled.nav`
  margin-bottom: calc(var(--grid) * 2);
`;

const Spacer = styled.div`
  width: 300px;
`;

const NavItem = styled.div<{
  isUnselectable?: boolean;
  isSelected?: boolean;
  isStrong?: boolean;
}>`
  font-weight: ${({ isStrong }) => (isStrong ? "600" : "300")};
  &:hover {
    background-color: ${({ isUnselectable }) =>
      isUnselectable ? "transparent" : "white"};
  }
  padding: calc(var(--grid) * 2);
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: ${({ isSelected }) =>
    isSelected ? "white" : "transparent"};
`;

export function SideNav({ title }: { title?: string }) {
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const pages = usePages();
  const sites = useSites();
  const agents = useAgents();

  return (
    <div>
      <Spacer />
      <NavWrapper>
        <>
          {(sites || []).map(({ site, role }) => (
            <NavGroup>
              {role === "owner" ? (
                <Link to={role === "owner" ? `/site/${site.id}` : ""}>
                  <NavItem isStrong isSelected={id === site.id}>
                    <Icon name="sitemap"></Icon> {site.name}
                  </NavItem>
                </Link>
              ) : (
                <>
                  <NavItem isStrong>
                    <Icon name="sitemap"></Icon> {site.name}
                  </NavItem>
                </>
              )}
              <>
                {(pages || [])
                  .filter(({ page }) => page.site === site.id)
                  .map(({ page }) => (
                    <Link to={`/page/${page.id}`}>
                      <NavItem isSelected={id === page.id}>{page.url}</NavItem>
                    </Link>
                  ))}
                {role === "owner" && (
                  <Link to={`/create/page?siteId=${site.id}`}>
                    <NavItem
                      isSelected={(
                        location.pathname + location.search
                      ).startsWith(`/create/page?siteId=${site.id}`)}
                    >
                      + Create page
                    </NavItem>
                  </Link>
                )}
              </>
            </NavGroup>
          ))}
          <NavGroup>
            <Link to={`/create/site`}>
              <NavItem
                isSelected={(location.pathname + location.search).startsWith(
                  `/create/site`
                )}
              >
                <Icon name="plus"></Icon> Create site
              </NavItem>
            </Link>
          </NavGroup>
        </>
        <NavGroup>
          <NavItem isUnselectable isStrong>
            <Icon name="user secret"></Icon> Agents
          </NavItem>
          {(agents || []).map((agent) => (
            <Link to={`/agent/${agent.id}`}>
              <NavItem isSelected={id === agent.id}>{agent.name}</NavItem>
            </Link>
          ))}
          <Link to={`/create/agent`}>
            <NavItem
              isSelected={(location.pathname + location.search).startsWith(
                `/create/agent`
              )}
            >
              + Create agent
            </NavItem>
          </Link>
        </NavGroup>
      </NavWrapper>
    </div>
  );
}
