import React from "react";
import { Header } from "../components/Header";
import { useParams } from "react-router-dom";
import { PageLayout } from "../layouts/PageLayout";
import { useSite } from "../data/useSites";
import { SetSiteForm } from "../components/forms/SetSiteForm";

export function ViewSite() {
  const { id } = useParams<{ id: string }>();
  const { site } = useSite(id) || {};

  return (
    <>
      <Header title={site?.name} />
      <PageLayout>
        <h2>Site configuration</h2>
        <SetSiteForm siteId={id} />
      </PageLayout>
    </>
  );
}
