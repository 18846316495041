import React from "react";
import { styled } from "linaria/react";
import { Link } from "react-router-dom";

import { useAgents } from "../data/useAgents";

export function AgentList() {
  const agents = useAgents();

  return (
    <>
      <h2>Agents</h2>
      <ul>
        {(agents || []).map((agent) => (
          <li>
            <Link to={`/agent/${agent.id}`}>{agent.name}</Link>
          </li>
        ))}
        <li>
          <Link to="/create/agent">Create agent</Link>
        </li>
      </ul>
    </>
  );
}
