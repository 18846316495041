import { styled } from "linaria/react";

export const FieldWrapper = styled.fieldset`
  display: flex;
  border: none;
  padding: 0;
  flex-direction: column;
  padding: 0 0 var(--grid) 0;
  max-width: calc(var(--grid) * 40);
`;
