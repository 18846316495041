import { Test } from "../../types/test";

export type Event = {
  time: number;
  type: "deploy";
  name: string;
};

export function extractEvents(tests: Test[]) {
  const events: Event[] = [];
  const sortedTests = tests.sort((a, b) => a.time - b.time);
  // Deploy events, based on scripts changed
  sortedTests.forEach((test, i) => {
    if (test.result.diff?.scripts?.percentChanged >= 0.2) {
      events.push({
        time: test.time,
        type: "deploy",
        name: "Deploy",
      });
    }
  });
  return events;
}
