import * as React from "react";
import { styled } from "linaria/react";
import { Link } from "react-router-dom";
import { Dropdown } from "semantic-ui-react";

const HeaderWrapper = styled.nav`
  a {
    color: var(--background);
  }
  position: fixed;
  width: 100%;
  z-index: 1;
  background-color: var(--headline);
  color: var(--background);
  height: calc(var(--grid) * 4);
  padding: var(--grid) 0;
  display: flex;
  font-weight: 400;
  justify-content: left;
  align-items: center;
  flex-direction: row;
`;

const Spacer = styled.div`
  height: calc(var(--grid) * 4);
`;

const TitleWrapper = styled.span`
  width: 300px;
  text-align: center;
`;

const Title = styled.span`
  border-bottom: 3px solid currentColor;
  height: 100%;
  font-weight: 600;
`;

const Actions = styled.div`
  margin: 0 var(--grid);
  right: 0;
  position: absolute;
`;

const Logo = styled.img`
  filter: invert(1);
  width: 20px;
  height: 17px;
  margin-right: var(--grid);
`;

const LogoWrapper = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`;

export function Header({ title }: { title?: string }) {
  return (
    <div>
      <HeaderWrapper>
        <TitleWrapper>
          <Link to="/">
            <LogoWrapper>
              <Logo
                width="32"
                height="32"
                alt={`Faster.style logo`}
                src={`${process.env.PUBLIC_URL}/favicon.ico`}
              ></Logo>
              <Title>faster.style</Title>
            </LogoWrapper>
          </Link>
        </TitleWrapper>
        <Actions>
          <div>
            <Dropdown text="Account">
              <Dropdown.Menu>
                <Dropdown.Item as={Link} to="/login" text="Login" />
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Actions>
      </HeaderWrapper>

      <Spacer />
    </div>
  );
}
