import { useFirestore, useAuth } from "reactfire";
import { PageFields } from "../types/page";

export function useSetPage() {
  const firestore = useFirestore();
  const auth = useAuth();
  return async function setPage(page: PageFields) {
    const uid = auth.currentUser?.uid;
    if (!uid) {
      return Promise.reject();
    }
    const newDoc = firestore.collection("page").doc();
    await newDoc.set({ ...page, owner: uid });
    return newDoc;
  };
}
