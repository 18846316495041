import React from "react";
import { styled } from "linaria/react";
import { Link } from "react-router-dom";

import { usePages } from "../data/usePages";
import { useSites } from "../data/useSites";
import { CreatePageCard, PageCard } from "./PageCard";

const PageList = styled.div`
  display: flex;
  flex-wrap: wrap;
  & > * {
    margin: var(--grid);
  }
`;

export function SiteList() {
  const pages = usePages();
  const sites = useSites();

  return (
    <>
      {(sites || []).map(({ site, role }) => (
        <>
          <h2>
            <Link to={`/site/${site.id}`}>{site.name}</Link>
          </h2>
          <PageList>
            {(pages || [])
              .filter(({ page }) => page.site === site.id)
              .map(({ page }) => (
                <PageCard page={page} />
              ))}
            {role === "owner" && <CreatePageCard siteId={site.id} />}
          </PageList>
        </>
      ))}
    </>
  );
}
