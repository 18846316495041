import React from "react";
import { Link } from "react-router-dom";
import { Header } from "../components/Header";
import { SiteList } from "../components/SiteList";
import { AgentList } from "../components/AgentList";

import { PageLayout } from "../layouts/PageLayout";

export function Home() {
  return (
    <>
      <Header title="Home" />
      <PageLayout>
        <SiteList></SiteList>
        <h2>
          <Link to="/create/site">Create site</Link>
        </h2>
        <AgentList></AgentList>
      </PageLayout>
    </>
  );
}
