import React, { FunctionComponent } from "react";
import { styled } from "linaria/react";
import { SideNav } from "../components/SideNav/SideNav";

const Page = styled.main<{ centered?: boolean }>`
  display: flex;
  justify-content: ${({ centered }) => (centered ? "center" : "left")};
`;

const PageContent = styled.section`
  padding: calc(3 * var(--grid));
`;

export const PageLayout: FunctionComponent<{
  centered?: boolean;
  disableSideNav?: boolean;
}> = ({ centered = false, disableSideNav = false, children }) => {
  return (
    <Page centered={centered}>
      {!disableSideNav && <SideNav />}
      <PageContent>{children}</PageContent>
    </Page>
  );
};
