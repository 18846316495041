import React from "react";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";

TimeAgo.addDefaultLocale(en);

export function Time({ ts, relative }: { ts?: number; relative?: boolean }) {
  if (!ts) {
    return null;
  }
  const date = new Date(ts);
  const timeAgo = new TimeAgo("en-US");

  return (
    <span title={`${date.toDateString()} - ${date.toTimeString()}`}>
      {relative ? timeAgo.format(date) : date.toTimeString()}
    </span>
  );
}
