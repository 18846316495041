import { ChartViewConfig } from "../../components/ChartOptions";
import { AreaChartData } from "../../components/charts/AreaChart";

export function filterAnomalies(
  chartConfig: ChartViewConfig,
  dataPointsWithAnomalies: AreaChartData[]
) {
  return dataPointsWithAnomalies.filter((data: AreaChartData, i: number) => {
    if (!dataPointsWithAnomalies[i - 1] || !dataPointsWithAnomalies[i + 1]) {
      return true;
    }
    if (isNaN(Number(chartConfig.filterAnomalies))) {
      return true;
    }
    const filterThreshold = Number(chartConfig.filterAnomalies);
    const thisValue = data.value || 0;
    const prevValue = dataPointsWithAnomalies[i - 1].value || 0;
    const nextValue = dataPointsWithAnomalies[i + 1].value || 0;
    const prevIncrease = (thisValue - prevValue) / prevValue;
    const nextIncrease = (nextValue - thisValue) / nextValue;
    if (prevIncrease > filterThreshold && nextIncrease < -filterThreshold) {
      return false;
    }
    if (prevIncrease < -filterThreshold && nextIncrease > filterThreshold) {
      return false;
    }
    return true;
  });
}
