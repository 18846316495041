import React, { useState } from "react";
import { PageFields } from "../types/page";
import { Header } from "../components/Header";
import { useParams } from "react-router-dom";
import { usePages } from "../data/usePages";
import { useFunctions } from "reactfire";
import { useTests } from "../data/useTests";
import { PageLayout } from "../layouts/PageLayout";
import { Time } from "../components/Time";
import { AreaChart } from "../components/charts/AreaChart";
import { formatDataForChart } from "../data/processing/formatDataForChart";
import { styled } from "linaria/react";
import { Test } from "../types/test";
import { ChartOptions, ChartViewConfig } from "../components/ChartOptions";
import { CssDiff } from "../components/CssDiff";
import { extractEvents } from "../data/processing/extractEvents";

const Charts = styled.div`
  display: flex;
  margin: var(--grid) 0;
  width: 100%;
  flex-wrap: wrap;

  & > * {
    margin: var(--grid);
  }

  h3 {
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: var(--grid);
  }
`;

const FloatingHeader = styled.div`
  height: calc(20 * var(--grid));
  z-index: 1;
  margin-top: calc(-3 * var(--grid));
`;

const FloatingHeaderFixed = styled.div`
  position: fixed;
  z-index: 1;
  padding: calc(3 * var(--grid)) 0;
  background: white;
  width: calc(
    100% - 300px - var(--grid) * 3
  ); /** 100% - sidebar width - main content padding */
`;

export function ViewPage({ page }: { page?: PageFields }) {
  const functions = useFunctions();
  const [chartConfig, setChartConfig] = useState<ChartViewConfig>({});
  const testNow = functions.httpsCallable("runTestForPage");

  const { id } = useParams<{ id: string }>();
  const pages = usePages();
  const tests = useTests(id);
  if (!pages || !id) {
    return null;
  }
  const { page: thisPage } = pages.find(({ page }) => page.id === id) || {};

  const mostRecentTestDate = tests.reduce((a, b) => Math.max(a, b.time), 0);

  return (
    <>
      <Header title={thisPage && thisPage.url} />
      <PageLayout>
        <FloatingHeader>
          <FloatingHeaderFixed>
            <ChartOptions onConfigChange={setChartConfig} />
            <button
              onClick={async () => {
                await testNow({ pageId: id });
              }}
            >
              Test now
            </button>
            <span>
              &nbsp; Last tested <Time relative ts={mostRecentTestDate} />
            </span>
          </FloatingHeaderFixed>
        </FloatingHeader>
        <Charts>
          {[
            {
              name: "Total declarations",
              fn: (test: Test) => test.result.declarations?.count,
            },
            {
              name: "!important declarations",
              fn: (test: Test) => test.result.declarations?.important,
            },
            {
              name: "Total rules",
              fn: (test: Test) => test.result.rule?.count,
            },
            {
              name: "Inline rules",
              fn: (test: Test) => test.result.rule?.inline.count,
            },
            {
              name: "Layout count",
              fn: (test: Test) => test.result.layoutMetrics?.layoutCount,
            },
            {
              name: "Recalc styles count",
              fn: (test: Test) => test.result.layoutMetrics?.recalcStyleCount,
            },
            {
              name: "Total inline declarations",
              fn: (test: Test) => test.result.rule?.inline.declarations,
            },
            {
              name: "Total bytes",
              fn: (test: Test) => test.result.bytes,
            },
            {
              name: "Declarations per rule",
              fn: (test: Test) =>
                test.result.declarations &&
                test.result.declarations.count / test.result.rule.count,
            },
            {
              name: "!important declarations (%)",
              fn: (test: Test) =>
                test.result.declarations &&
                test.result.declarations.important /
                  test.result.declarations.count,
            },
            {
              name: "Inline declarations (%)",
              fn: (test: Test) =>
                test.result.rule &&
                test.result.rule.inline.declarations /
                  test.result.declarations.count,
            },
            // TODO: make dynamic based on site config
            {
              name: "url.aphrodite total time (ms)",
              fn: (test: Test) =>
                test.result.profileMetrics &&
                test.result.profileMetrics["url.aphrodite"] &&
                test.result.profileMetrics["url.aphrodite"].timeTotal / 1000,
            },
            {
              name: "url.aphrodite samples",
              fn: (test: Test) =>
                test.result.profileMetrics &&
                test.result.profileMetrics["url.aphrodite"] &&
                test.result.profileMetrics["url.aphrodite"].samplesPresent,
            },
            {
              name: "url.react total time (ms)",
              fn: (test: Test) =>
                test.result.profileMetrics &&
                test.result.profileMetrics["url.react"] &&
                test.result.profileMetrics["url.react"].timeTotal / 1000,
            },
            {
              name: "url.react samples",
              fn: (test: Test) =>
                test.result.profileMetrics &&
                test.result.profileMetrics["url.react"] &&
                test.result.profileMetrics["url.react"].samplesPresent,
            },
            {
              name: "function.resolveLTR total time (ms)",
              fn: (test: Test) =>
                test.result.profileMetrics &&
                test.result.profileMetrics["function.resolveLTR"] &&
                test.result.profileMetrics["function.resolveLTR"].timeTotal /
                  1000,
            },
            {
              name: "function.resolveLTR samples",
              fn: (test: Test) =>
                test.result.profileMetrics &&
                test.result.profileMetrics["function.resolveLTR"] &&
                test.result.profileMetrics["function.resolveLTR"]
                  .samplesPresent,
            },
            {
              name: "function.createLTR total time (ms)",
              fn: (test: Test) =>
                test.result.profileMetrics &&
                test.result.profileMetrics["function.createLTR"] &&
                test.result.profileMetrics["function.createLTR"].timeTotal /
                  1000,
            },
            {
              name: "function.createLTR samples",
              fn: (test: Test) =>
                test.result.profileMetrics &&
                test.result.profileMetrics["function.createLTR"] &&
                test.result.profileMetrics["function.createLTR"].samplesPresent,
            },
            {
              name: "Scripts changed",
              fn: (test: Test) =>
                test.result.diff &&
                test.result.diff.scripts &&
                test.result.diff.scripts.changed,
            },
            {
              name: "Scripts changed (%)",
              fn: (test: Test) =>
                test.result.diff &&
                test.result.diff.scripts &&
                test.result.diff.scripts.percentChanged,
            },
          ].map(({ name, fn }) => (
            <div>
              <h3>{name}</h3>
              <AreaChart
                data={formatDataForChart(tests, fn, chartConfig)}
                pointOverlay={
                  chartConfig.overlay === "points"
                    ? formatDataForChart(tests, fn, {
                        ...chartConfig,
                        filterAnomalies: undefined,
                        batchingWindow: 0,
                        movingAverage: 1,
                      })
                    : undefined
                }
                eventOverlay={
                  chartConfig.overlay === "events"
                    ? extractEvents(tests)
                    : undefined
                }
                width={600}
                height={400}
                curve={chartConfig.curve}
                display={chartConfig.display}
              />
            </div>
          ))}
        </Charts>

        <ul>
          {window.location.search.indexOf("debug") > -1 &&
            tests.map((test, i) => (
              <li key={test.id}>
                <Time ts={test.time} />, {test.result.bytes}
                <CssDiff
                  css1={test.result.css}
                  css2={tests[i - 1]?.result.css}
                />
                <textarea value={test.result.css}></textarea>
              </li>
            ))}
        </ul>
      </PageLayout>
    </>
  );
}
