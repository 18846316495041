import React from "react";
import { Header } from "../components/Header";
import { PageLayout } from "../layouts/PageLayout";
import { SetAgentForm } from "../components/forms/SetAgentForm";

export function CreateAgent() {
  return (
    <>
      <Header title="Create agent" />
      <PageLayout>
        <SetAgentForm />
      </PageLayout>
    </>
  );
}
