import { useFirestore, useAuth } from "reactfire";
import { AgentFields } from "../types/agent";

export function useSetAgent() {
  const firestore = useFirestore();
  const auth = useAuth();
  return function setSite(agent: AgentFields, id?: string) {
    const uid = auth.currentUser?.uid;
    if (!uid) {
      return Promise.reject();
    }
    return firestore
      .collection("agent")
      .doc(id) // If undefined, it's a create. Otherwise update.
      .set({ ...agent, owner: uid });
  };
}
