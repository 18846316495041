import { useFirestore, useAuth, useFirestoreCollectionData } from "reactfire";
import { Page } from "../types/page";
import { Role } from "../types/role";
import { useSites } from "./useSites";

export function usePages() {
  const pagesWithRoles: { [id: string]: { role: Role; page: Page } } = {};

  const auth = useAuth();
  const uid = auth.currentUser?.uid;
  const firestore = useFirestore();

  const ownerPagesRef = firestore
    .collection("page")
    .where("owner", "==", uid || "none");
  const ownerPages = useFirestoreCollectionData<Page>(ownerPagesRef, {
    idField: "id",
  });

  const viewerSiteIds = useSites()
    .filter((site) => site.role === "viewer")
    .map(({ site }) => site.id);

  const viewerPagesRef =
    viewerSiteIds.length === 0
      ? firestore.collection("none")
      : firestore.collection("page").where("site", "in", viewerSiteIds);
  const viewerPages = useFirestoreCollectionData<Page>(viewerPagesRef, {
    idField: "id",
  });

  if (!uid) {
    return [];
  }

  (viewerPages.data || []).forEach((page) => {
    pagesWithRoles[page.id] = {
      role: "viewer",
      page,
    };
  });
  console.log(viewerPages);

  // Owner pages intentionally override viewer pages in case for some reason you're an owner and a viewer of a page
  (ownerPages.data || []).forEach((page) => {
    pagesWithRoles[page.id] = {
      role: "owner",
      page,
    };
  });

  return Object.values(pagesWithRoles);
}

export function usePage(id: string) {
  const pages = usePages();
  if (!pages) {
    return null;
  }
  return pages.find(({ page }) => page.id === id);
}
