import { median } from "d3-array";
import { ChartViewConfig } from "../../components/ChartOptions";
import { AreaChartData } from "../../components/charts/AreaChart";

function medianInWindow(data: AreaChartData[], i: number, half_window: number) {
  return median(
    data.map((d) => d.value).slice(i - half_window, i + half_window)
  ) as number;
}

export function filterHampel(
  chartConfig: ChartViewConfig,
  data: AreaChartData[]
) {
  if (chartConfig.filterAnomalies !== "hampel") {
    return data;
  }
  const threshold = 3;
  const half_window = 3;
  var n = data.length;
  const dataValues = data.map((d) => d.value);
  var data_copy: (AreaChartData | undefined)[] = [...data];
  var ind: any[] = [];
  var L = 1.4826;
  for (var i = half_window + 1; i < n - half_window; i++) {
    var med = medianInWindow(data, i, half_window);
    var MAD =
      L *
      median(
        data
          .map((d) => d.value)
          .slice(i - half_window, i + half_window)
          // eslint-disable-next-line no-loop-func
          .map(function (e) {
            return Math.abs(e - med);
          })
      )!;
    if (Math.abs(dataValues[i] - med) / MAD > threshold) {
      data_copy[i] = undefined;
      ind = ind.concat(i);
    }
  }

  return data_copy.filter((x) => x !== undefined) as AreaChartData[];
}
